import React from 'react';
import { Section, SectionAlternate } from 'components/organisms';
import {  Hero, 
          Benefits, 
          Articles, 
          DoohTypes, 
          DoohIdeas, 
          GoToLocations } 
        from './components';

import {
  articles,
  ideas
} from './data';

const Dooh = () => (
  <div>
    <Hero />
    <Benefits />
    <Section>
      <Articles data={articles}/>
    </Section>
    <SectionAlternate innerNarrowed>
      <DoohTypes />
    </SectionAlternate>
    <Section>
      <DoohIdeas data={ideas}/>
    </Section>
    <SectionAlternate innerNarrowed>
      <GoToLocations />
    </SectionAlternate>
  </div>
);

export default Dooh;
