import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Grid,
  Typography
} from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { CardProduct, CardBase } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  fontWeight700: {
    fontWeight: 700,
  },
  image: {
    borderBottomLeftRadius: '15%'
  },
  customMediaClass: {
    height: 'auto'
  }
}));

const DoohIdeas = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <SectionHeader
        fadeUp
        title={
          <span>
            Ideen für eine{' '}
            <Typography component="span" variant="inherit" color="primary">
              DooH-Kampagne
            </Typography>
          </span>
        }/>
      
      <Grid container spacing={2}>

        {data.map((item, index) => (
          
          <Grid key={index} item xs={12} sm={4} md={4} data-aos="fade-up">
          
            <CardProduct withShadow liftUp
              mediaClassName={classes.customMediaClass}
              mediaContent={ <Image src={item.image.src} className={classes.image}/> }
              cardContent={
                <Grid container spacing={1} className={classes.noPaddingTop}>
                
                  <Grid item xs={12}>
                    <Typography variant="h6" color="textPrimary" align="left" className={classes.fontWeight700}>
                      {item.title}
                    </Typography>
                  </Grid>
                
                  <Grid item xs={12}>
                    <Typography variant="body1" color="textSecondary" align="left">
                      {item.subtitle}
                    </Typography>
                  </Grid>
                
                </Grid>
              }
            />

          </Grid>

        ))}

      </Grid>
    </div>
  );
};

DoohIdeas.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default DoohIdeas;
