import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Box } from '@material-ui/core';
import { Image, LearnMoreLink } from 'components/atoms';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  tinyPaddingBottom: {
    paddingBottom: 10,
  },
  smallPaddingBottom: {
    paddingBottom: 20,
  },
  readMoreLink: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(.5),
      marginBottom: theme.spacing(0),
    },
  },
  grid: {
    borderRadius: theme.spacing(1),
    transition:
      'box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease',
    '&:hover': {
      boxShadow:
        '0 1.5rem 2.5rem rgba(22,28,45,.1),0 .3rem 0.5rem -.50rem rgba(22,28,45,.05) !important',
      transform: 'translate3d(0,-5px,0)',
    },
  },
  lightBorder2Svg: {
    filter: `drop-shadow(1px 1px 0px ${theme.palette.common.white}) drop-shadow(-1px 1px 0px ${theme.palette.common.white}) drop-shadow(1px -1px 0px ${theme.palette.common.white}) drop-shadow(-1px -1px 0px ${theme.palette.common.white})`
  }
}));


const Articles = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
    
  return (
    <div className={className} {...rest}>
      <SectionHeader className={classes.smallPaddingBottom}
        label="LET'S READ DOOH!"
        title="Fachzeitschriften, Fernsehsender & Magazine über DooH"
        subtitle="Digital-Out-of-Home-Werbung (DooH) oder auch digitale Außenwerbung ist die Werbeform, um Ihrer Dienstleistung oder Ihrem Produkt die Aufmerksamkeit im öffentlichen Raum zu schenken."
        fadeUp={true}
        align="left"
      />
      <Grid container justify="center" spacing={isMd ? 9 : 2}>
        {data.map((item, index) => (
          <Grid
            item
            container
            direction="column"
            xs={6}
            md={4}
            key={index}
            data-aos="fade-up"
            className={classes.grid}
          >            
            <Box className={classes.tinyPaddingBottom}>
              <Image
                className={classes.lightBorder2Svg}
                src={item.logo}
                height='43x'
                width={item.logo_width}
              />
            </Box>
            <SectionHeader
              titleVariant="h6"
              subtitle={item.subtitle}
              subtitleColor="textPrimary"
              subtitleVariant="body1"
              align="left"
              disableGutter
            />
            <div style={{ flexGrow: 1 }} />
            <LearnMoreLink
              title="Artikel lesen"
              href={item.article}
              target="_blank"
              variant="subtitle1"
              className={classes.readMoreLink}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Articles.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Articles;
