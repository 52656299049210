import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { HeroShaped } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundSize: 'contain',
    backgroundColor: theme.palette.alternate.main,
  },
  cover: {
    position: 'relative',
    zIndex: 9,
    width: '100%',
    height: '100%',
  },
  image: {
    objectFit: 'cover',
  },
}));


const Hero = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const isMd = useMediaQuery(useTheme().breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <HeroShaped
        leftSide={
          <SectionHeader
            title={
              <div>
                Die Welt ändert sich,{isMd ? ' ' : <br/>}
                <Typography component="span" variant="inherit" color="primary">
                  auch Außenwerbung
                </Typography>
              </div>
            }
            subtitle="Let's DooH it."
            subtitleColor="textPrimary"
            /*ctaGroup={[
              <Button variant="contained" color="primary" size="large">
                get started
              </Button>,
            ]}*/
            align="left"
            data-aos="fade-up"
            titleVariant="h3"
          />
        }
        rightSide={
          <div className={classes.cover}>
            <Image
              src={require('assets/images/dooh/city/newyork.jpg')}
              srcSet={require('assets/images/dooh/city/newyork.jpg')}
              className={classes.image}
              lazyProps={{
                width: '100%',
                height: '100%',
              }}
            />
          </div>
        }
      />
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
