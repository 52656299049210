import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, colors, makeStyles } from '@material-ui/core';
import { IconAlternate } from 'components/molecules';
import { DescriptionListIcon, HeroBackground } from 'components/organisms';

const data = [
  {
    icon: 'fas fa-arrows-alt',
    title: 'Unausweichlich',
    subtitle:
      'Digitale Außenwerbung kann nicht ausgeschaltet oder übersehen werden, wie es bei Online-Werbung der Fall sein kann.',
  },
  {
    icon: 'far fa-eye',
    title: 'Aufmerksamkeit',
    subtitle:
      'Laut einer Studie: 63% der Menschen sagen das Digital Signage ihre Aufmerksamkeit auf sich zieht.',
  },
  {
  icon: 'fas fa-undo',
    title: 'Wiedererkennung',
    subtitle:
      'Besonders durch dauerhafte / regelmäßige Präsenz im öffentlichen Raum.',
  },
  {
    icon: 'fas fa-users',
    title: 'Reale Werbung',
    subtitle:
      'Trifft tatsächlich jeden. Allein durch die Größe und die flächendeckende Verfügbarkeit im öffentlichen Raum ein auffälliger und effektiver Werbeträger',
  },
  {
    icon: 'far fa-money-bill-alt',
    title: 'Budgetorientiert',
    subtitle:
      'Sie setzen Ihr Werbebudget fest, wir spielen Ihre Werbung nur bis zu Ihrem Limit ab.',
  },
  {
    icon: 'fas fa-hand-holding-usd',
    title: 'Kosteneffizienter',
    subtitle:
      'Vergleichsweise kostengünstiger als Plakatwerbung, Zeitungsanzeigen oder Radiospots',
  }
];

const useStyles = makeStyles({
  descriptionListIcon: {
    '& h6, p': { 
      'color': 'black' 
    }
  }
});

const Benefits = ({ className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true });

  return (
    <div className={className} {...rest}>
      <HeroBackground backgroundColor={colors.amber[500]}>
        <Grid container spacing={isMd ? 4 : 4}>
          {data.map((item, index) => (
            <Grid key={index} item xs={6} sm={6} md={4} data-aos={'fade-up'}>
              <DescriptionListIcon 
                className={classes.descriptionListIcon}
                title={item.title}
                subtitle={item.subtitle}
                icon={
                  <IconAlternate
                    fontIconClass={item.icon}
                    size="medium"
                    color={colors.amber}
                  />
                }
                align="left"
              />
            </Grid>
          ))}
        </Grid>
      </HeroBackground>
    </div>
  );

};

Benefits.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Benefits;
