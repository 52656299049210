export const articles = [
  {
    logo: require('assets/images/fachzeitschrift-logos/wuv.svg'),
    logo_width: '80px',
    subtitle: '“DooH ist nicht nur reichweitenstark und zielgenau, sondern auch schnell und flexibel”',
    article: 'https://www.wuv.de/marketing/digital_out_of_home_wirbt_in_eigener_sache'  
  },
  {
    logo: require('assets/images/fachzeitschrift-logos/horizont.svg'),
    logo_width: '160px',
    subtitle: 'Kunden entdeckten immer „stärker die Bewegtbildfaktoren und andere dynamische Elemente“',
    article: 'https://www.horizont.at/agenturen/news/blick-in-die-bundeslaender-sichtbarkeit-als-erfolgsgarant-84146'
  },
  {
    logo: require('assets/images/fachzeitschrift-logos/exchange4media.svg'),
    logo_width: '160px',
    subtitle: 'Billboards are a crucial touchpoint that empowers marketers to engage with consumers in a more personalized manner […]',
    article: 'https://www.exchange4media.com/out-of-home-news/how-ooh-can-help-brands-with-consumer-insights-in-todays-market-environment-111573.html'
  },
  {
    logo: require('assets/images/fachzeitschrift-logos/invidis.svg'),
    logo_width: '160px',
    subtitle: '[…] die Menschen schenken ihrer Umgebung nach der vielen Zeit in den eigenen Wänden deutlich mehr Aufmerksamkeit […]',
    article: 'https://invidis.de/2021/03/nach-corona-lockerungen-mehr-aufmerksamkeit-fuer-dooh/'
  },
  {
    logo: require('assets/images/fachzeitschrift-logos/welt.svg'),
    logo_width: '110px',
    subtitle: '„Wer Werbung machen will, muss also dorthin gehen, wo die Menschen sind: in den öffentlichen Raum.“',
    article: 'https://www.welt.de/wirtschaft/article209945091/Werbung-Drei-Gruender-nehmen-sich-Airbnb-als-Vorbild.html'
  },
  {
    logo: require('assets/images/fachzeitschrift-logos/wuv.svg'),
    logo_width: '80px',
    subtitle: 'Die Zukunft der Vermarktung wird in der Digitalisierung liegen wird und im Grunde nur noch in Echtzeit ablaufen.',
    article: 'https://www.wuv.de/specials/w_v_future_driven_marketing_day/vermarktung_kuenftig_nur_noch_in_echtzeit'
  }
];

export const ideas = [
  {
    image: {
        src: require('assets/images/dooh/werbeideen/inspiration-angebot-rabatt-sale.jpg'),
        srcSet: require('assets/images/dooh/werbeideen/inspiration-angebot-rabatt-sale.jpg'),
        alt: '...',
    },
    title: 'Angebote / Rabatte',
    subtitle: 'Sie haben ein aktuelles Angebot für Ihre Dienstleistungen oder Produkte.'
  },
  {
    image: {
        src: require('assets/images/dooh/werbeideen/neues-produkt-symbol.jpg'),
        srcSet: require('assets/images/dooh/werbeideen/neues-produkt-symbol.jpg'),
        alt: '...',
    },
    title: 'Neuheit',
    subtitle: 'Sie möchten auf ein neues oder bestehendes Produkt oder eine Dienstleistung aufmerksam machen. '
  },
  {
    image: {
      src: require('assets/images/dooh/werbeideen/startup.jpg'),
      srcSet: require('assets/images/dooh/werbeideen/startup.jpg'),
        alt: '...',
    },
    title: 'Start-Up',
    subtitle: 'Sie sind ein Startup-Unternehmen und möchten sich bekannt machen.'
  }
];